"use client";
import Link from "next/link";
import { loginSchema } from "@/utils/validation/SignupSchema";
import { InputField, PasswordField } from "@/components/ui";
import { Formik, Form } from "formik";
import { signIn, useSession } from "next-auth/react";
import { useRouter, usePathname } from "next/navigation";
import { Loader } from "@/components/ui";
import { useState } from "react";
import { showToast } from "@/app/CustomToastProvider";
import { useAppDispatch } from "@/lib/hooks";

interface LoginFormValues {
  email: string;
  password: string;
  login_via: string;
}
interface LoginProps {
  onSuccess?: () => any;
  onSignupClick?: () => any;
}
const LoginForm = (props: LoginProps) => {
  const currentUrl = usePathname();

  const isClient = typeof window !== "undefined";
  const params = currentUrl === "/login" 
  ? "/dashboard/db-booking" 
  : (isClient ? window.location.href : "/dashboard/db-booking"); // Ensure a valid fallback URL

  // const params: any  = (currentUrl == "/login") ? "/dashboard/db-booking" : (typeof window !== "undefined" && window?.location?.href);

  const [isLoading, setIsLoading] = useState(false); 
  const router = useRouter();
  const initialValues: LoginFormValues = {
    email: "",
    password: "",
    login_via: "web",
  };
  const onSubmit = async (data: LoginFormValues) => {
    
    const callbackUrl = params && params.startsWith("/") ? `${window.location.origin}${params}` : params;

    console.log("callbackUrl", callbackUrl)
    setIsLoading(true); 
    try {
      const result = await signIn("roave-signin", {
        redirect: false,
        ...data,
        ...(params != null && { callbackUrl: callbackUrl })
      });
     
      // console.log('onSubmit result',result, params);
      if (result?.error) {
        showToast(result?.error, 'danger');
        setIsLoading(false); 
      } else {
        setIsLoading(false); 
        props.onSuccess && props.onSuccess();
        // console.log("else", params)
        router.push(params); // Redirect to a protected route
      }
    } catch (error) {
      setIsLoading(false); 
      console.error("Error during login:", error);
    }
  };
  // dispatch(login({ url: "v1/auth/user/login", payload: data }));

  
  return (
    <>
    {isLoading && <Loader show={isLoading} />}
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={loginSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form className="row y-gap-20">
          {/* {JSON.string  ify(currentUrl)} */}

          <div className="col-12">
            {currentUrl == "/login" && <h1 className="text-22 fw-500">Welcome back</h1>}
            <p className="mt-10">
              Don&apos;t have an account yet?{" "}
              {currentUrl == "/login" ? <Link href="/signup" className="text-blue-1">
                Sign up for free
              </Link>: <button type="button" onClick={() => props.onSignupClick && props.onSignupClick()}  className="text-blue-1">Sign up for free</button>}
            </p> 
          </div>
        
        
          {/* ss{JSON.stringify(params)} */}
          <div className="col-12">
            <InputField name="email" label="Email" type="email" />
          </div>
          

          <div className="col-12">
            <PasswordField name="password" label="Password" />
          </div>
        

          <div className="col-12">
            <Link href="/forgot-password" className="text-14 fw-500 text-blue-1 underline">
              Forgot your password?
            </Link>
          </div>
       

          <div className="col-12">
            <button
              type="submit"
              className="button py-20 -dark-1 bg-blue-1 text-white w-100"
            >
              Sign In 
              {/* <div className="icon-arrow-top-right ml-15" /> */}
            </button>
          </div>
          {/* End .col */}
        </Form>
      )}
    </Formik>
    </>
  );
};

export default LoginForm;
