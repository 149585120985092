// components/AuthModal.tsx
import React, { useCallback, useState } from "react";
import {
  Modal,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import LoginForm from "./LoginForm";
import SignUpForm from "./SignUpForm";
import "./AuthModal.css";
import LoginWithSocial from "./LoginWithSocial";
import Link from "next/link";
interface AuthModalProps {
  isOpen: boolean;
  toggle: () => void;
}

const AuthModal: React.FC<AuthModalProps> = ({ isOpen, toggle }) => {
  const [activeTab, setActiveTab] = useState<string>("signin");

  const handleTabClick = useCallback((tab: string) => {
    setActiveTab(tab);
  }, []);

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered size="l" fade={false}>
      <ModalHeader toggle={toggle}>Welcome Back</ModalHeader>

      {/* Tabs for switching between Sign In and Sign Up */}
      <Nav tabs className="justify-content-center mt-3">
        <NavItem>
          <NavLink
            className={activeTab === "signin" ? "active" : ""}
            onClick={() => handleTabClick("signin")}
          >
            Sign In
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "signup" ? "active" : ""}
            onClick={() => handleTabClick("signup")}
          >
            Sign Up
          </NavLink>
        </NavItem>
      </Nav>

      {/* Tab Content */}
      <TabContent activeTab={activeTab} className="p-3">
        <TabPane tabId="signin">
          <LoginForm
            onSuccess={toggle}
            onSignupClick={() => handleTabClick("signup")}
          />
        </TabPane>
        <TabPane tabId="signup">
          <SignUpForm
            onSuccess={toggle}
            onLoginClick={() => handleTabClick("signin")}
          />
        </TabPane>
        <div className="row y-gap-20 pt-30">
                  <div className="col-12">
                    <div className="text-center">or sign in with</div>
                  </div>
                  <LoginWithSocial />
                  {/* <div className="col-12">
                    <div className="text-center px-30">
                      By creating an account, you agree to our  <Link href={"/terms-and-conditions"} className="text-blue-1">
                        Terms & conditions
                      </Link>{" "}
                      and <Link href={"/privacy-policy"} className="text-blue-1">
                        Privacy Policy
                      </Link>.
                    </div>
                  </div> */}
                </div>
                
      </TabContent>
    </Modal>
  );
};

export default AuthModal;
