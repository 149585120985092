const ContactInfo = () => {
  
  const contactContent = [
    {
      id: 1,
      title: "Toll Free Customer Care",
      action: `tel:${process.env.NEXT_PUBLIC_ADMIN_CONTACT}`,
      text: process.env.NEXT_PUBLIC_ADMIN_CONTACT,
    },
    {
      id: 2,
      title: "Need live support?",
      action: `mailto:${process.env.NEXT_PUBLIC_ADMIN_EMAIL}`,
      text: process.env.NEXT_PUBLIC_ADMIN_EMAIL,
    },
  ];

  return (
    <>
      {contactContent.map((item) => (
        <div className="mb-20" key={item?.id}>
          <div className={"text-14"}>{item?.title}</div>
          <a href={item?.action} className="text-18 fw-500 text-dark-1 mt-5">
            {item?.text}
          </a>
        </div>
      ))}
    </>
  );
};

export default ContactInfo;
