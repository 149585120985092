"use client";
import Link from "next/link";
import { signUpSchema } from "@/utils/validation/SignupSchema";
import { CheckboxField, InputField, PasswordField } from "@/components/ui";
import { Formik, Form, ErrorMessage } from "formik";
import { signIn } from "next-auth/react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { Loader } from "@/components/ui";
import { useEffect, useState } from "react";
import { showToast } from "@/app/CustomToastProvider";
import { getReferralCodeDetail } from "@/lib/features/auth/authSlice";
import { useAppDispatch } from "@/lib/hooks";
import { Check } from "react-bootstrap-icons";
interface SignUpFormValues {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  registration_via: string;
  agree: boolean;
  referral_code: string;
}
interface SignUpProps {
  onSuccess?: () => any;
  onLoginClick?: () => any;
}
const SignUpForm = (props: SignUpProps) => {
  const dispatch = useAppDispatch();
  const query: any = useSearchParams();
  const currentUrl = usePathname();
  const isClient = typeof window !== "undefined";
  const params = currentUrl === "/signup" 
  ? "/dashboard/db-booking" 
  : (isClient ? window.location.href : "/dashboard/db-booking"); // Ensure a valid fallback URL

  const [isLoading, setIsLoading] = useState(false);
  const [isReferral, setIsReferral] = useState<boolean>(false);
  const router = useRouter();
  const initialValues: SignUpFormValues = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    registration_via: "web",
    agree: true,
    referral_code: "",
  };

  const onSubmit = async (data: SignUpFormValues) => {
    setIsLoading(true);
    const callbackUrl = params && params.startsWith("/") ? `${window.location.origin}${params}` : params;
    try {
      const result = await signIn("roave-signup", {
        redirect: false,
        ...data,
        ...(params != null && { callbackUrl: callbackUrl }),
      });

      // console.log("onSubmit result", result);
      if (result?.error) {
        showToast(result?.error, "danger");
        console.log("onSubmit Signup", result?.error);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        props.onSuccess && props.onSuccess();
        router.replace(params); // Redirect to a protected route
      }
    } catch (error) {
      console.log("Signup error", error)
      setIsLoading(false);
      console.error("Error during signup:", error);
    }
  };

  return (
    <>
      {isLoading && <Loader show={isLoading} />}
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={signUpSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, setFieldValue }) => {
          useEffect(() => {
            const fetchTokenDetails = async () => {
              const referral_code = query.get("referral_code");
              if (referral_code) {
                const { payload } = await dispatch(
                  getReferralCodeDetail({
                    url: `v1/auth/user/get-referral-code-details`,
                    payload: { referral_code },
                  })
                );
                const { status, data } = payload;
                if (status === 200) {
                  const { referral_code, email } = data;
                  setFieldValue("referral_code", referral_code || "");
                  setIsReferral(true);
                }
              }
            };
            fetchTokenDetails();
          }, [query, dispatch, setFieldValue]);

          return (
            <Form className="row y-gap-20">
              {/* {JSON.stringify(values)} */}
              <div className="col-12">
                {currentUrl == "/signup" && (
                  <h1 className="text-22 fw-500">Welcome back</h1>
                )}
                <p className="mt-10">
                  Already have an account yet?{" "}
                  {currentUrl == "/signup" ? (
                    <Link href="/login" className="text-blue-1">
                      Log in
                    </Link>
                  ) : (
                    <button
                      type="button"
                      onClick={() => props.onLoginClick && props.onLoginClick()}
                      className="text-blue-1"
                    >
                      Log in
                    </button>
                  )}
                </p>
              </div>
              <div className="col-12 col-sm-6">
                <InputField name="first_name" label="First Name" />
              </div>
              <div className="col-12 col-sm-6">
                <InputField name="last_name" label="Last Name" />
              </div>
              <div className="col-12">
                <InputField name="email" label="Email" type="email" />
              </div>
              <div className="col-12">
                <PasswordField name="password" label="Password" />
              </div>
              <div className="col-12">
                <PasswordField
                  name="confirmPassword"
                  label="Confirm Password"
                />
              </div>
              {isReferral && (
                <div className="col-12">
                  <p className="text-15 mt-10 text-green-2 fw-500">
                    <Check size={28} />
                    Referral code {values?.referral_code} applied
                  </p>
                </div>
              )}
              {/* <div className="col-12">
                <div className="d-flex position-relative">
                  <div className="form-checkbox mt-5">
                    <CheckboxField
                      name="referral"
                      id="referral"
                      showError={false}
                      onValueChange={(e) => {
                        setIsReferral(e);
                      }}
                    />
                  </div>
                  <div className="text-15 lh-15 text-light-1 ml-10">
                    Do you have referral code?
                  </div>
                </div>
                <div className="position-relative">
                  <ErrorMessage
                    name={"referral"}
                    component={"div"}
                    className="error-message-1"
                  />
                </div>
              </div> */}
              <div className="col-12">
                <div className="d-flex position-relative">
                  <div className="form-checkbox mt-5">
                    <CheckboxField name="agree" id="agree" showError={false} />
                  </div>
                  <div className="text-15 lh-15 text-light-1 ml-10">
                    By creating an account, you agree to our{" "}
                    <Link
                      href={"/terms-and-conditions"}
                      className="text-blue-1"
                    >
                      Terms & conditions
                    </Link>{" "}
                    and{" "}
                    <Link href={"/privacy-policy"} className="text-blue-1">
                      Privacy Policy
                    </Link>
                    .
                  </div>
                </div>
                <div className="position-relative">
                  <ErrorMessage
                    name={"agree"}
                    component={"div"}
                    className="error-message-1"
                  />
                </div>
              </div>
              <div className="col-12">
                <button
                  type="submit"
                  className="button py-20 -theme-blue-color bg-theme-brown-color text-white w-100"
                >
                  Sign Up
                  {/* <div className="icon-arrow-top-right ml-15" /> */}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default SignUpForm;
