import * as yup from "yup";

export const signUpSchema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Confirm password is required"),
  registration_via: yup.string().required("Registration via is required"),
  agree: yup
    .boolean()
    .oneOf([true], "You must accept the privacy policy and terms and conditions"),
  referral_code: yup.string().optional()
});

export const loginSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  login_via: yup.string().required("Login via is required"),
});
export const forgotSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
});

export const resetSchema = yup.object().shape({
  otp: yup.string().min(6, "OTP is required").required("OTP is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  new_password: yup.string().required("Password is required"),
  confirm_password: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("new_password")], "Passwords must match"),
});


export const changePasswordSchema = yup.object({
  old_password: yup
      .string()
      .required("Old password is required"),
  new_password: yup
      .string()
      .required("Password is required"),
    confirm_password: yup
      .string()
      .required("Confirm password is required")
      .oneOf([yup.ref("new_password")], "Passwords must match")
})

