"use client";
import { signIn } from "next-auth/react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { Loader } from "@/components/ui";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { showToast } from "@/app/CustomToastProvider";
import Cookies from "js-cookie"; 
import { Google } from "react-bootstrap-icons";

const LoginWithSocial = () => {
  
  const query: any = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const currentUrl = usePathname();
  const params: any =
    currentUrl == "/login" || currentUrl == "/signup"
      ? "/dashboard/db-booking"
      : typeof window !== "undefined" && window?.location?.href;

  useEffect(() => {
    const referralCode = query.get("referral_code");
    if (referralCode) {
      Cookies.set("referral_code", referralCode, { expires: 1 }); // ✅ Store in cookie
    }
  }, [query]);
  
  const router = useRouter();
  const onSubmit = async () => {
    setIsLoading(true);
    try {

      // setCookie( 'additionalAuthParams', JSON.stringify({ appPublicKey: appPublicKey, }) );

      const result = await signIn("google", {
        redirect: false,
        ...(params != null && { callbackUrl: params }),
        state: query?.get("referral_code")
        // referral_code: query?.get("referral_code") || "",
      }); 

      console.log("onSubmit result", result);
      if (result?.error) {
        showToast(result?.error, "danger");
        console.log("onSubmit Signup", result?.error);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        router.replace(params); // Redirect to a protected route
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error during signup:", error);
    }
  };


  return (
    <>
      {/* <div className="col-md-6 col-12">
        <button className="button col-12 -outline-blue-1 text-blue-1 py-15 rounded-8 ">
          <i className="icon-apple text-15 mr-10" />
          Facebook
        </button>
      </div> */}
      {isLoading && <Loader show={isLoading} />}
      <div className="col-12">
        <button
          className="button col-12 -outline-red-1 text-red-1 py-15 rounded-8 "
          onClick={onSubmit}
        >
          <Google className="icon-google text-15 mr-10" />
          Google
        </button>
      </div>
    </>
  );
};

export default LoginWithSocial;
