"use client";

import Link from "next/link";

import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import {
  homeItems,
  blogItems,
  pageItems,
  dashboardItems,
  categorieMobileItems,
  categorieMegaMenuItems,
} from "@/components/data/mainMenuData";
import { isActiveLink } from "@/utils/linkActiveChecker";
import Social from "@/components/common/social/Social";
import ContactInfo from "./ContactInfo";
import { usePathname, useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import LOGO_LIGHT from "@/img/general/logo-light.svg";
import Image from "next/image";
import useAuthModal from "@/hooks/useAuthModal";
import AuthModal from "@/components/common/AuthModal";
import { useSession } from "next-auth/react";
import { logout } from "@/lib/features/profile/profileSlice";
import { useAppDispatch } from "@/lib/hooks";
const MobileMenu = () => {
  const dispatch = useAppDispatch();
  const pathname: any = usePathname();
  const { isModalOpen, openModal, closeModal } = useAuthModal();
  const { data: session, status } = useSession();

  const [isActiveParent, setIsActiveParent] = useState<boolean | number>(false);
  const [isActiveNestedParentTwo, setisActiveNestedParentTwo] = useState<
    boolean | string
  >(false);
  const [isActiveNestedParent, setisActiveNestedParent] = useState<
    boolean | number
  >(false);

  const router = useRouter();

  useEffect(() => {
    categorieMegaMenuItems.map((megaMenu: any) => {
      megaMenu?.menuCol?.map((megaCol: any) => {
        megaCol?.menuItems?.map((item: any) => {
          item?.menuList?.map((list: any) => {
            if (list.routePath?.split("/")[1] == pathname.split("/")[1]) {
              setIsActiveParent(true);
              setisActiveNestedParentTwo(item?.title);
              setisActiveNestedParent(megaMenu?.id);
            }
          });
        });
      });
    });
  }, []);

  return (
    <>
      <AuthModal isOpen={isModalOpen} toggle={closeModal} />
      <div className="pro-header d-flex align-items-center justify-between border-bottom-light">
        <Link href="/">
          <Image
            src={LOGO_LIGHT}
            alt="logo icon"
            width={80}
            height={80}
            loading="lazy"
            quality={50}
          />

          {/* <img src="/img/general/logo-dark.svg" alt="brand" /> */}
        </Link>
        {/* End logo */}

        <div
          className="fix-icon"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <i className="icon icon-close"></i>
        </div>
        {/* icon close */}
      </div>
      {/* End pro-header */}

      <Sidebar width="400" backgroundColor="#fff">
        <Menu>
          {/* End  All Home Menu */}
          <MenuItem
            href="/"
            className={pathname === "/" ? "menu-active-link" : "inactive-menu"}
          >
            Home
          </MenuItem>

          {/* End  Desitinations Menu */}

          {/* <SubMenu label="Blog" className={ blogItems.some((item=>item.routePath?.split('/')[1] == pathname.split('/')[1])) ? "menu-active-link":''}>
              {blogItems.map((item, i) => (
                <MenuItem
                  key={i}
                  onClick={()=>router.push(item.routePath)}
                  className={
                    isActiveLink(item.routePath, pathname)
                      ? "menu-active-link"
                      : "inactive-menu"
                  }
                >
                  {item?.name}
                </MenuItem>
              ))}
            </SubMenu> */}
          {/* End  All Blog Menu */}

          {/* End  All Dashboard Menu */}

          <MenuItem
            href="/blog"
            className={pathname === "/blog" ? "menu-active-link" : "inactive-menu"}
          >
            Blog
          </MenuItem>

          <MenuItem
            href="/deals"
            className={pathname === "/deals" ? "menu-active-link" : "inactive-menu"}
          >
            Deals
          </MenuItem>
          {/* End Contact  Menu */}
        </Menu>
      </Sidebar>

      <div className="mobile-footer px-20 py-5 border-top-light"></div>

      <div className="pro-footer">
        <ContactInfo />
        <div className="mt-10">
          <h5 className="text-16 fw-500 mb-10">Follow us on social media</h5>
          <div className="d-flex x-gap-20 items-center">
            <Social />
          </div>
        </div>
        <div className="mt-20">
          {status === "unauthenticated" ? (
            <div
              onClick={openModal}
              className=" button -dark-1 px-30 fw-400 text-14 bg-blue-1 h-50 text-white"
            >
              Sign In / Register
            </div>
          ) : (
            <>
              <Link
                href={`/dashboard/db-booking`}
                className=" button -dark-1 px-30 fw-400 text-14 bg-blue-1 h-50 text-white"
              >
                Dashboard
              </Link>

              <button
                onClick={() => dispatch(logout({ url: "v1/user/logout" }))}
                className=" button -dark-1 px-30 fw-400 text-14 bg-blue-1 h-50 text-white mt-20 w-100"
              >
                Logout
              </button>
            </>
          )}
        </div>
      </div>
      {/* End pro-footer */}
    </>
  );
};

export default MobileMenu;
